@use "mixins" as *
.notFoundContainer
  height: calc(100% - 200px - var(--header_height))
  overflow: hidden
  position: absolute
  width: 100%
  left: 0
  min-height: 700px
  > div:first-of-type
    min-height: 100%
    display: flex

@media (max-height: 900px)
  .notFoundContainer
    height: calc(100% - var(--header_height))
    min-height: auto
    .notFoundPage
      min-height: 680px
  .goToHome
    bottom: 0

.notFoundPage
  background: var(--body_bg) var(--icon-not-found-bg) no-repeat center bottom
  flex: 1
  position: relative
  display: flex
  flex-direction: column
  justify-content: stretch
  padding-bottom: 210px
  min-height: 490px
  > div
    background: var(--notFount_divBg)
    min-width: 1000px
    width: 100%
    max-width: 1100px
    margin: 0 auto
    flex: auto
    position: relative
    > span:not(.notFoundLogo)
      position: absolute !important
      bottom: -80px
      left: 50%
      transform: translateX(-50%)

.notFoundLogo
  @include flex
  margin: 310px auto 0 !important
  justify-content: center
  &.sr-betvivo span
    margin-top: 14px
    svg
      height: 100px

  span
    @include flex
    svg
      width: auto
      height: 42px

.leftBubble
  background: var(--icon-not-found-red-bubble) no-repeat bottom right
  left: 200px
  animation: leftBubbleAnimate 1s
  width: 260px
  height: 234px
  position: absolute
  bottom: 200px
  padding: 18px 30px 0
  color: rgb(var(--secondaryColor))
  display: flex
  flex-direction: column
  align-items: center

  span
    text-transform: uppercase
    @include bold

  > *
    position: relative
    animation: leftBubbleContentAnimate 1s

  > span:first-of-type
    display: inline-flex
    flex-direction: column
    align-items: center
    justify-content: center
    font-size: 18px
    border-radius: 3px
    padding: 6px 8px
    min-width: 80px
    background: #842024

    strong
      font-size: 36px

  > span:last-of-type
    font-size: 16px
    margin-top: 10px
    margin-bottom: 8px
    min-width: 200px
    text-align: center

  p
    text-align: center
    font-size: 14px
    line-height: 18px
    width: 200px

  @keyframes leftBubbleAnimate
    0%
      background-size: 0
      opacity: 0
    40%
      background-size: 0
      opacity: 0
    80%
      background-size: 100%
      opacity: 1
    100%
      background-size: 100%
      opacity: 1
  @keyframes leftBubbleContentAnimate
    0%
      opacity: 0
    80%
      opacity: 0
    100%
      opacity: 1

.goToHome
  height: 50px
  width: 180px
  position: absolute
  bottom: 0
  left: 50%
  border-radius: 4px 4px 0 0
  transform: translateX(-50%)
  @include flex
  justify-content: center
  padding: 0 8px
  line-height: 16px
  background: var(--button_primaryBg)
  font-size: 16px
  @include bold
  color: var(--button_color)
  &:hover
    background: var(--button_primaryHoverBg)
  &:active
    background: var(--button_primaryHoverBg)
